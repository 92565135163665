<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="vorgangData"
      :fields="vorgangFields"
      :busy="isVorgangLoading"
      responsive
      hover
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template #cell(flugticketAusgestellt)="row">
        <CustomIconChecked :value="row.item.flugticketAusgestellt" />
      </template>
      <template #head(thWertGesamt)>
        <span>TH Gesamt</span>
        <i
          class="fas fa-asterisk text-danger icon-sm"
          title="Zum Zeitpunkt der Buchung"
          v-b-tooltip.hover
          style="align-self: start; position: absolute"
        ></i>
      </template>
      <template #head(thWertInlandsflug)>
        <span>TH Inlandsflug</span>
        <i
          class="fas fa-asterisk text-danger icon-sm"
          title="Zum Zeitpunkt der Buchung"
          v-b-tooltip.hover
          style="align-self: start; position: absolute"
        ></i>
      </template>
      <template #head(thWertMain)>
        <span>TH Langstrecke</span>
        <i
          class="fas fa-asterisk text-danger icon-sm"
          title="Zum Zeitpunkt der Buchung"
          v-b-tooltip.hover
          style="align-self: start; position: absolute"
        ></i>
      </template>
      <template #cell(thWertMain)="row">
        <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(row.item.id)">
          {{ getThwerteAtBuchungsdatumByVorgangId(row.item.id, 'thWertMain') }}
        </template>
        <b-spinner v-else small></b-spinner>
      </template>
      <template #cell(thWertInlandsflug)="row">
        <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(row.item.id)">
          {{ getThwerteAtBuchungsdatumByVorgangId(row.item.id, 'thWertInlandsflug') }}
        </template>
        <b-spinner v-else small></b-spinner>
      </template>
      <template #cell(thWertGesamt)="row">
        <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(row.item.id)">
          {{ getThwerteAtBuchungsdatumByVorgangId(row.item.id, 'thWertGesamt') }}
        </template>
        <b-spinner v-else small></b-spinner>
      </template>
      <template #cell(aufgaben)="row">
        <b-badge :id="'tooltip-target-vorgang' + row.item.id">
          {{ row.item.aufgaben?.length }}
        </b-badge>
        <b-tooltip
          :target="'tooltip-target-vorgang' + row.item.id"
          triggers="hover"
          custom-class="custom-tooltip"
          v-if="row.item.aufgaben?.length > 1"
        >
          <AufgabenWidget
            :openAufgabenId="openAufgabenId"
            class="mt-3"
            :ansicht="'aufgabe-details'"
            :aufgaben="row.item.aufgaben || []"
          ></AufgabenWidget>
        </b-tooltip>
      </template>
    </b-table>
  </div>
</template>
<script>
import { format } from 'date-fns';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import { GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID } from '@/core/vorgaenge/stores/vorgaenge.module';
import { mapState } from 'vuex';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import CustomIconChecked from '@/components/common/custom-icon-checked.vue';

export default {
  props: {
    vorgangData: { type: Array, required: true },
    openAufgabenId: { type: Number, default: null },
    aufgabenKategorie: { type: String },
  },
  components: { VorgangStateWidget, AufgabenWidget, CustomIconChecked },
  data() {
    return {
      isVorgangLoading: false,
      vorgangDataExpanded: [],
      vorgangFields: [
        {
          tdClass: 'align-middle',
          key: 'id',
          label: 'BNR',
        },
        {
          tdClass: 'align-middle',
          key: 'pnr',
          label: 'PNR',
          formatter: value => value.join(', '),
        },
        {
          tdClass: 'align-middle',
          key: 'teilnehmerNames',
          label: 'Teilnehmernamen',
        },
        {
          tdClass: 'align-middle',
          key: 'flugticketAusgestellt',
          label: 'Flugticket ausgestellt',
        },
        {
          tdClass: 'align-middle',
          key: 'thWertMain',
          label: 'TH Langstrecke',
        },
        {
          tdClass: 'align-middle',
          key: 'thWertInlandsflug',
          label: 'TH Inlandsflug',
        },
        {
          tdClass: 'align-middle',
          key: 'thWertGesamt',
          label: 'TH Gesamt',
        },
        {
          tdClass: 'align-middle',
          key: 'ticketingTimelimit',
          label: 'TKTL',
        },
        { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
      ],
    };
  },
  computed: {
    ...mapState({
      thwerteAtBuchungsdatumByVorgangId: state => state.vorgaenge.thwerteAtBuchungsdatumByVorgangId,
    }),
    jumpIntoFluegeReisetermindetails() {
      return (
        this.aufgabenKategorie === 'FlugticketsVorzeitigAusstellen' ||
        this.aufgabenKategorie === 'VorabpruefungTicketBeiGruppe' ||
        this.aufgabenKategorie === 'TicketfreigabeYield' ||
        this.aufgabenKategorie === 'GruppeTicketsAusstellen' ||
        this.aufgabenKategorie === 'SpecialServicesBuchen' ||
        this.aufgabenKategorie === 'NegoQuotieren' ||
        this.aufgabenKategorie === 'IndividualisierungBuchen' ||
        this.aufgabenKategorie === 'Flugcheck'
      );
    },
  },
  watch: {
    vorgangData: {
      handler: function (v) {
        const reiseterminIds = [
          ...new Set(this.vorgangData.map(vorgang => vorgang.hotelleistungen[0].reisetermin.id)),
        ];
        if (reiseterminIds.length === 1) {
          this.$store.dispatch(GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID, {
            reiseterminId: reiseterminIds[0],
            vorgaenge: this.vorgangData,
          });
        }
      },
    },
  },
  mounted() {
    const reiseterminIds = [
      ...new Set(this.vorgangData.map(vorgang => vorgang.hotelleistungen[0].reisetermin.id)),
    ];
    if (reiseterminIds.length === 1) {
      this.$store.dispatch(GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID, {
        reiseterminId: reiseterminIds[0],
        vorgaenge: this.vorgangData,
      });
    }
  },
  methods: {
    getThwerteAtBuchungsdatumByVorgangIdisLoading(vorgangId) {
      return this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.isLoading;
    },
    getThwerteAtBuchungsdatumByVorgangId(vorgangId, key) {
      const vorgangData = this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.data;
      if (vorgangData && vorgangData[key]) {
        return this.$options.filters.currency(vorgangData[key]);
      }
      return '-';
    },

    kundenName(item) {
      if (item?.kunde?.master) {
        return item?.kunde?.master.vorname + ' ' + item?.kunde?.master.nachname;
      } else return '';
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
    rowClicked(item) {
      if (this.jumpIntoFluegeReisetermindetails) {
        window.open(
          '/#/reisetermine/' +
            item.hotelleistungen[0].reisetermin.id +
            '/?vorgangdetailstab=fluege&scrollToFlugdaten=true',
          '_blank'
        );
        return;
      }

      window.open('/#/reisetermine/' + item.hotelleistungen[0].reisetermin.id, '_blank');
    },
  },
};
</script>
