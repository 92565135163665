<template>
  <span>
    <i v-if="value" :class="['fas fa-exclamation text-danger', size]"></i>
    <span v-else>-</span>
  </span>
</template>

<script>
export default {
  name: 'CustomIconExclamation',
  props: {
    value: {
      type: [Boolean, null],
    },
    size: {
      type: String,
      default: 'fa-lg',
    },
  },
};
</script>
